import React, { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import * as CONTRACTS from "../../Contract";
import { injectModels } from "../../Redux/injectModels";
import Web3 from "web3";
import toast from "react-hot-toast";
import { getPrice } from "../uinswap_v3_price/uinswapbot";

const Investments = (props) => {
  const [showInvestmentModal, setShowInvestmentModal] = useState(false);
  const [investmentToken, setInvestmentToken] = useState("");
  const [investmentDetails, setInvestmentDetails] = useState([]);
  const [web3, setWeb3] = useState("");
  const [price, setPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => setShowInvestmentModal(true);
  const handleCloseModal = () => setShowInvestmentModal(false);
  const handleInputChange = (e) => setInvestmentToken(e.target.value);

  const ADDRESS = process.env.REACT_APP_INVESTOR_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const { address, isConnecting, isDisconnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      window.location.reload();
    },
  });

  const addInvestment = async () => {
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);
    try {
      const ercContract = new web3.eth.Contract(
        CONTRACTS.ERC20.abi,
        "0x5dd1cbf142F4B896D18aF835C1734363b0d50fB0"
      );
      if (!web3.utils.isAddress(address)) {
        throw new Error("Invalid wallet address");
      }

      const tx1 = ercContract.methods.approve(
        ADDRESS,
        web3.utils.toWei(investmentToken, "ether")
      );

      props.application.setLoading(true);
      const gas1 = await tx1.estimateGas({ from: address });
      const gasPrice1 = await web3.eth.getGasPrice(); // Get current gas price from the network
      const tx1Receipt = await tx1.send({
        from: address,
        gas: gas1,
        gasPrice: gasPrice1 * 2,
      });

      const contract = new web3.eth.Contract(
        CONTRACTS.NakaInvestor.abi,
        ADDRESS
      );
      const tx = contract.methods.addInvestment(investmentToken);
      props.application.setLoading(true);
      const gas = await tx.estimateGas({ from: address });
      const gasPrice2 = await web3.eth.getGasPrice();
      const receipt = await tx.send({
        from: address,
        gas: gas,
        gasPrice: gasPrice2 * 2,
      });
      props.application.setLoading(false);
      toast.success("Investment added successfully");
      handleCloseModal();
    } catch (error) {
      props.application.setLoading(false);
      toast.error(error.message);
    }
  };

  const INFURA_KEYS = [
    process.env.REACT_APP_INFURA_KEY1,
    process.env.REACT_APP_INFURA_KEY2,
    process.env.REACT_APP_INFURA_KEY3,
    process.env.REACT_APP_INFURA_KEY4,
  ];

  const getInfuraUrl = () => {
    const currentHour = new Date().getHours(); // Get the current hour (0 to 23)
    const keyIndex = Math.floor(currentHour / 6); // Divide the day into 4 segments of 6 hours
    const selectedApiKey = INFURA_KEYS[keyIndex]; // Select the appropriate API key

    return `https://polygon-mainnet.infura.io/v3/${selectedApiKey}`; // Return full Infura URL with API key
  };

  const getTokenPrice = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const infuraUrl = getInfuraUrl();
      const price = await getPrice(
        1, //Amount of token 1
        infuraUrl, // RPC url
        "WQGDRA3YZDMSFEUVUKRSEDZS3FGGTMCG6W", // Block Scan Api Key
        "0x1B8C03343473Bc648c1b82c85C1DD32C9d63430e", // Pool Address
        "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6" // Quoter Address
      );
      setPrice(Number(1 / Number(price)));
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
      console.log(ex, "error");
    }
  };

  const getInvestment = async () => {
    try {
      props.application.setLoading(true);
      if (address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(
          CONTRACTS.NakaInvestor.abi,
          ADDRESS
        );
        const receipt = await contract.methods.getInvestments(address).call();
        setInvestmentDetails(receipt.userInvestments_);
        props.application.setLoading(false);
      } else {
        console.log("No address found");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
    }
  };

  const handleRelease = async (id, maturityDate, isClaimed) => {
    const now = Math.floor(Date.now() / 1000); // Get current time in seconds
    if (isClaimed) {
      toast.dismiss();

      toast.error("You have already claimed this investment.");

      return;
    }
    if (now < maturityDate) {
      toast.dismiss();

      toast.error("Investment is not yet matured.");

      return;
    }

    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);

    try {
      const contract = new web3.eth.Contract(
        CONTRACTS.NakaInvestor.abi,
        ADDRESS
      );
      const tx = contract.methods.claimInsvestment(id);
      props.application.setLoading(true);
      const gas = await tx.estimateGas({ from: address });
      const gasPrice2 = await web3.eth.getGasPrice();
      const receipt = await tx.send({
        from: address,
        gas: gas,
        gasPrice: gasPrice2 * 2,
      });
      props.application.setLoading(false);
      toast.success("Investment claimed successfully");
      getInvestment();
      handleCloseModal();
    } catch (error) {
      props.application.setLoading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);
    setWeb3(web3);
    getInvestment();
    getTokenPrice();
  }, []);

  return (
    <>
      <section className="total-earning">
        <div className="user-full-details">
          <div className="investment">
            <div className="total-investment">
              <div className="tbing-transaction">
                <h6>Naka Withdrawal History</h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-md-12 maturity-data">
              {investmentDetails.length > 0 ? (
                investmentDetails
                  .slice()
                  .reverse()
                  .map((investment, index) => (
                    <div key={index} className="sdtyge">
                      <section className="individual-investment-section">
                        <span
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor:
                              Math.floor(Date.now() / 1000) >
                              investment.maturityDate_
                                ? "green"
                                : "red",
                            marginRight: "10px",
                            position: "absolute",
                            right: "0px",
                            top: "10px",
                          }}
                        ></span>
                        <div className="investdata-card">
                          <h6>
                            Investment ID:{" "}
                            <span>
                              {200000 +
                                Number(investment.id_) * 50 +
                                Number(investment.id_)}
                            </span>
                          </h6>
                          {web3 && (
                            <h6>
                              Amount:{" "}
                              <span>
                                {Number(
                                  web3?.utils.fromWei(
                                    investment.amount_,
                                    "ether"
                                  )
                                ).toFixed(3)}{" "}
                                NKT ={" "}
                                {isLoading
                                  ? "fetching"
                                  : `${(
                                      Number(
                                        web3?.utils.fromWei(
                                          investment.amount_,
                                          "ether"
                                        )
                                      ) / price
                                    ).toFixed(3)}`}{" "}
                                USDT
                              </span>
                            </h6>
                          )}
                          <h6>
                            Start Date:{" "}
                            <span>
                              {new Date(
                                investment.startDate_ * 1000
                              ).toLocaleString("en-US", {
                                timeZone: "UTC",
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })}
                            </span>
                          </h6>
                          <h6>
                            Maturity Date:{" "}
                            <span>
                              {new Date(
                                investment.maturityDate_ * 1000
                              ).toLocaleString("en-US", {
                                timeZone: "UTC",
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              })}
                            </span>
                          </h6>

                          <button
                            onClick={() =>
                              handleRelease(
                                investment.id_,
                                investment.maturityDate_,
                                investment.isClaimed
                              )
                            }
                            style={{
                              backgroundColor: investment.isClaimed
                                ? "green"
                                : "#f0f0f0",
                              color: investment.isClaimed ? "white" : "black",
                              border: "none",
                              padding: "10px 20px",
                              borderRadius: "5px",
                              cursor: investment.isClaimed
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            {investment.isClaimed
                              ? "Already Claimed"
                              : "Release"}
                          </button>
                        </div>
                      </section>
                    </div>
                  ))
              ) : (
                <p
                  style={{
                    marginTop: "15px",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  No investments found
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default injectModels(["admin", "application"])(Investments);
